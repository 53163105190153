var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.existUsers && _vm.existUsers.length)?[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('h4',[_vm._v("Exist User ("+_vm._s(_vm.existUsers.length)+")")]),_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showExistUser = !_vm.showExistUser}}},[_vm._v(_vm._s(_vm.showExistUser ? 'hide' : 'show'))])],1),(_vm.showExistUser)?_c('vue-good-table',{staticClass:"mb-10",attrs:{"columns":_vm.headers.filter((h) => h.field !== 'action'),"rows":_vm.existUsers,"line-numbers":true,"styleClass":"vgt-table striped","sort-options":{
        enabled: true,
        initialSortBy: { field: 'name', type: 'asc' },
      },"search-options":{
        enabled: true,
        placeholder: 'Search',
      }}}):_vm._e()]:_vm._e(),(_vm.headers.length > 0)?_c('v-form',{ref:"tableForm",model:{value:(_vm.tableValid),callback:function ($$v) {_vm.tableValid=$$v},expression:"tableValid"}},[_c('vue-good-table',{attrs:{"columns":_vm.headers,"rows":_vm.users,"line-numbers":true,"styleClass":"vgt-table striped","pagination-options":{
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 20, 50, 100],
        dropdownAllowAll: true,
        setCurrentPage: 1,
        jumpFirstOrLast: false,
        firstLabel: 'First',
        lastLabel: 'Last',
        nextLabel: 'Next',
        prevLabel: 'Prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All',
        infoFn: (params) =>
          `Showing ${params.firstRecordOnPage} - ${params.lastRecordOnPage} Of ${params.totalRecords}  ||  Page ${params.currentPage} / ${params.totalPage}`,
      },"sort-options":{
        enabled: true,
        initialSortBy: { field: 'name', type: 'asc' },
      },"search-options":{
        enabled: true,
        placeholder: 'Search',
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editUser(props)}}},[_vm._v(" fa-pen-square ")]),_c('span',{staticClass:"mx-2"}),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.removeUser(props)}}},[_vm._v(" fa-trash-alt ")])],1)]):_c('span',[_c('h3',{staticStyle:{"min-height":"20px"}},[_vm._v(" "+_vm._s(_vm.users[props.index][props.column.field])+" ")]),(props.column.rules)?[(
                props.column.rules[0](
                  _vm.users[props.index][props.column.field]
                ) !== true
              )?_c('div',{staticStyle:{"color":"red","font-size":"10px"}},[_vm._v(" "+_vm._s(props.column.rules[0](_vm.users[props.index][props.column.field]))+" ")]):_vm._e()]:_vm._e()],2)]}}],null,false,3555220915)},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("No User Found.")])])],1):_vm._e(),_c('v-row',{staticClass:"ma-5",attrs:{"align":"center"}},[(!_vm.loading)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){{
          _vm.createDialog = true;
          _vm.editIndex = null;
          _vm.user = {
            name: '',
            email: `@${_vm.company.emailFormat}.com`,
            countryCode: '+91',
            phone: '',
            designation: '',
            // password: '',
          };
        }}}},[_vm._v(" Add User Row")]):_vm._e(),_c('v-spacer'),(_vm.loading)?_c('v-progress-circular',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):(_vm.users && _vm.users.length)?_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.tableValid,"color":"success"},on:{"click":_vm.uploadUsers}},[_vm._v(" Upload User ")]):_vm._e(),(!_vm.tableValid)?_c('div',{staticClass:"ml-5"},[_vm._v("Some User data invaild.")]):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.editIndex != null ? 'Edit User' : 'Add User'))]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_vm._l((_vm.headers),function(header){return [(header.field != 'action')?_c('v-text-field',{key:header.field,attrs:{"label":header.label,"rules":header.rules},model:{value:(_vm.user[header.field]),callback:function ($$v) {_vm.$set(_vm.user, header.field, $$v)},expression:"user[header.field]"}}):_vm._e()]})],2),_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"success"},on:{"click":_vm.addRowDone}},[_vm._v("Done")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }