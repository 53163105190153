<template>
  <v-container>
    <template v-if="existUsers && existUsers.length">
      <v-row class="ma-0" align="center">
        <h4>Exist User ({{ existUsers.length }})</h4>
        <v-btn
          text
          color="primary"
          class="ml-2"
          @click="showExistUser = !showExistUser"
          >{{ showExistUser ? 'hide' : 'show' }}</v-btn
        >
      </v-row>
      <vue-good-table
        v-if="showExistUser"
        class="mb-10"
        :columns="headers.filter((h) => h.field !== 'action')"
        :rows="existUsers"
        :line-numbers="true"
        styleClass="vgt-table striped"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'name', type: 'asc' },
        }"
        :search-options="{
          enabled: true,
          placeholder: 'Search',
        }"
      >
      </vue-good-table>
    </template>
    <v-form v-if="headers.length > 0" ref="tableForm" v-model="tableValid">
      <vue-good-table
        :columns="headers"
        :rows="users"
        :line-numbers="true"
        styleClass="vgt-table striped"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
          position: 'bottom',
          perPageDropdown: [10, 20, 50, 100],
          dropdownAllowAll: true,
          setCurrentPage: 1,
          jumpFirstOrLast: false,
          firstLabel: 'First',
          lastLabel: 'Last',
          nextLabel: 'Next',
          prevLabel: 'Prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All',
          infoFn: (params) =>
            `Showing ${params.firstRecordOnPage} - ${params.lastRecordOnPage} Of ${params.totalRecords}  ||  Page ${params.currentPage} / ${params.totalPage}`,
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'name', type: 'asc' },
        }"
        :search-options="{
          enabled: true,
          placeholder: 'Search',
        }"
      >
        <div slot="emptystate">No User Found.</div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'action'">
            <div @click.stop>
              <v-icon small @click="editUser(props)"> fa-pen-square </v-icon>
              <span class="mx-2"></span>
              <v-icon small @click="removeUser(props)"> fa-trash-alt </v-icon>
            </div>
          </span>

          <span v-else>
            <h3 style="min-height: 20px">
              {{ users[props.index][props.column.field] }}
            </h3>
            <template v-if="props.column.rules">
              <div
                v-if="
                  props.column.rules[0](
                    users[props.index][props.column.field]
                  ) !== true
                "
                style="color: red; font-size: 10px"
              >
                {{
                  props.column.rules[0](users[props.index][props.column.field])
                }}
              </div>
            </template>
          </span>
        </template>
      </vue-good-table>
    </v-form>
    <v-row class="ma-5" align="center">
      <v-btn
        v-if="!loading"
        color="primary"
        class="ml-2"
        @click="
          {
            createDialog = true;
            editIndex = null;
            user = {
              name: '',
              email: `@${company.emailFormat}.com`,
              countryCode: '+91',
              phone: '',
              designation: '',
              // password: '',
            };
          }
        "
      >
        Add User Row</v-btn
      >
      <v-spacer></v-spacer>
      <v-progress-circular
        v-if="loading"
        class="my-10"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <v-btn
        v-else-if="users && users.length"
        :disabled="!tableValid"
        color="success"
        class="ml-2"
        @click="uploadUsers"
      >
        Upload User
      </v-btn>
      <div class="ml-5" v-if="!tableValid">Some User data invaild.</div>
    </v-row>

    <v-dialog v-model="createDialog" max-width="500px">
      <v-card>
        <v-card-title>{{
          editIndex != null ? 'Edit User' : 'Add User'
        }}</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <template v-for="header in headers">
              <v-text-field
                v-if="header.field != 'action'"
                :key="header.field"
                :label="header.label"
                v-model="user[header.field]"
                :rules="header.rules"
              />
            </template>
          </v-form>

          <v-btn :disabled="!valid" color="success" @click="addRowDone"
            >Done</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from 'firebase/app';
import { mapActions, mapState, mapGetters } from 'vuex';
import { encrypt, decrypt } from '../services/encryption_service';

export default {
  name: 'AddUsers',
  data() {
    return {
      showExistUser: false,
      headers: [],
      editIndex: null,
      user: {
        name: '',
        email: '@gmail.com',
        countryCode: '+91',
        phone: '',
        designation: '',
        // password: '',
      },
      createDialog: false,
      users: [],
      existUsers: [],
      valid: true,
      tableValid: true,
      company: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
      hradmin: (state) => state.hradmin,
    }),
    ...mapGetters(['getRole']),
  },
  async mounted() {
    if (this.getRole?.id != this.hradmin) {
      this.$router.push('/');
      return;
    }
    const data = this.$route.params.data;
    if (data?.users) {
      this.users = data.users;
    }
    this.company = await this.fetchCompany(this.currentUser.companyId);
    this.headers = [
      {
        label: 'Name',
        align: 'start',
        sortable: true,
        field: 'name',
        rules: [(v) => !!v || 'Name is required'],
      },
      {
        label: 'Email',
        field: 'email',
        sortable: true,
        rules: [
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (
              (pattern.test(value) &&
                value.includes(`@${this.company.emailFormat}.`)) ||
              'Invalid Company e-mail.'
            );
          },
        ],
      },
      {
        label: 'countryCode',
        field: 'countryCode',
        sortable: true,
        rules: [
          (value) => {
            if (value.length > 0) {
              return (
                (value.startsWith('+') &&
                  value.length > 1 &&
                  value.length < 4) ||
                'countryCode is invalid eg: +91'
              );
            } else {
              return true;
            }
          },
        ],
      },
      {
        label: 'Phone',
        field: 'phone',
        sortable: true,
        rules: [
          (value) => {
            if (value.length > 0) {
              return (
                (value.length == 10 && !isNaN(value)) ||
                'Phone Number Should be 10 digit'
              );
            } else {
              return true;
            }
          },
        ],
      },
      {
        label: 'Designation',
        field: 'designation',
        sortable: true,
        rules: [(v) => !!v || 'Designation is required'],
      },
      // {
      //   label: 'Password',
      //   field: 'password',
      //   rules: [(v) => !!v || 'Password is required'],
      // },
      {
        label: 'Action',
        field: 'action',
        sortable: false,
        width: '75px',
      },
    ];
    // this.user.password = this.company.commonPassword;
    this.user.email = `@${this.company.emailFormat}.com`;
    this.$refs.tableForm?.validate();
  },
  methods: {
    ...mapActions(['fetchCompany']),
    editUser(props) {
      this.createDialog = true;
      this.editIndex = props.index;
      this.user = { ...this.users[props.index] };
    },
    removeUser(props) {
      this.users = this.users.filter((_, i) => i !== props.index);
    },
    addRowDone() {
      if (this.$refs.form.validate()) {
        if (this.editIndex != null) {
          this.users[this.editIndex] = this.user;
        } else {
          this.users.push(this.user);
        }
        this.createDialog = false;
      }
    },
    async uploadUsers() {
      const uploadUsers = [];
      const eu = [];

      this.loading = true;
      try {
        const promises = this.users.map(async (user) => {
          if (!user['countryCode']?.startsWith('+')) {
            user['countryCode'] = `+${user['countryCode']}`;
          }
          const httpsCallable = firebase
            .functions()
            .httpsCallable('checkIfUserExist');
          const resultEmail = await httpsCallable({
            email: user['email'],
          });
          if (resultEmail.data) {
            eu.push(user);
          } else {
            const resultPhone = await httpsCallable({
              phone: `${user['countryCode']}${user['phone']}`,
            });
            if (resultPhone.data) {
              eu.push(user);
            } else {
              uploadUsers.push(user);
            }
          }

          return true;
        });
        await Promise.all(promises);

        this.users = uploadUsers;

        this.existUsers = eu;

        if (uploadUsers.length == 0) {
          this.loading = false;
          alert('No User Found To Upload.\nbecause all user is already exits.');
          return;
        }
        if (process.env.NODE_ENV == 'development') {
          firebase.functions().useEmulator('localhost', 5001);
        }

        const httpsCallableStore = firebase
          .functions()
          .httpsCallable('storeBulkUser');
        const result = await httpsCallableStore({
          users: uploadUsers,
          company: this.company,
        });
        this.loading = false;

        if (result.data) {
          this.users = [];
          alert(
            `we uploaded ${uploadUsers.length} user successfully.${
              eu.length > 0
                ? ` and found ${eu.length} user is already exits.`
                : ''
            }`
          );
          this.$router.push('/create-cards-template');
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.v-messages__message {
  color: red;
}
</style>
